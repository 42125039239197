<script setup>
import { computed, ref, reactive, onMounted } from "vue";
import { useDownloadFile } from "@/utils/vue_helpers";
import { getWorkflowApprovers, snackBarErrorMessage, snackBarSuccessMessage, snackBarInfoFunction, fetchAllRenovationTypes } from "../buildings_business_logic";
import ViewItemSectionTitle from "../../utils/ViewItemSectionTitle.vue";
import BackButton from "@/components/utils/BackButton.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import { getFormattedBillDate, getFormattedMoney } from "@/utils/functions";
import Table from "../../utils/Table.vue";
import { actions } from "@/utils/constants";
import ActionButton from "../../utils/ActionButton.vue";
const { downloadFile } = useDownloadFile();


const { snackbar_info } = snackBarInfoFunction()

const props = defineProps({
    demolition_application: {
        type: Object,
        default: null,
    }
});

const workflow_approvers_array = ref([])

const demolitionData = {
    demolition_reason: null,
}


const buildingDetails = computed(() => {
    let buildingDetails = {};

    buildingDetails = getBuildingDetails(props.demolition_application);
    return buildingDetails;
});

const bills_and_receipts_array = computed(() => {
    const bills_and_receipts_array = [{ 'id': '1', 'type': 'Bill', 'number': '', 'date': '', 'amount': '', 'status': '' }, { 'id': '2', 'type': 'Receipt', 'number': '', 'date': '', 'amount': '', 'status': '' }]
    return bills_and_receipts_array

});

const bills_and_receipts_object = computed(() => {
    const bills_and_receipts_object = props.demolition_application.building_demolition_bill?.bills
    return bills_and_receipts_object

});

function getBuildingDetails(renovation_app) {
    const demolitionDetails = {
        section1: {},
        section2: {},
    };
    demolitionDetails.section1["Parcel Number"] = renovation_app.building_application.building.parcel_no;
    demolitionDetails.section1["Parcel Number"] = renovation_app.building_application.building.parcel_no;
    demolitionDetails.section1["Subcounty"] = renovation_app.building_application.building.sub_counties.sub_county_name;
    demolitionDetails.section1["Ward"] = renovation_app.building_application.building.ward.ward_name;
    demolitionDetails.section1["Town"] = renovation_app.building_application.building.towns.town_name;
    demolitionDetails.section1["Latitude"] = renovation_app.building_application.building.latitude;
    demolitionDetails.section1["Longitude"] = renovation_app.building_application.building.longitude;

    demolitionDetails.section2["Cost"] = renovation_app.building_application.building.cost;
    demolitionDetails.section2["Unit of Measure"] = 'Sq Meters'
    demolitionDetails.section2["Plinth Area"] = renovation_app.building_application.building.plinth_area
    //   demolitionDetails.section2["Development Type"] = renovation_app.building_application.building.building_development_type.type_of_development

    //building_application.building.building_development_type.type_of_development


    demolitionDetails.section2["Number of Floors"] = renovation_app.building_application.building.number_of_floors
    demolitionDetails.section2["Approval Number"] = renovation_app.building_application.building.approval_number
    demolitionDetails.section2["Physical Location/Lanmark"] = renovation_app.building_application.building.physical_address

    return demolitionDetails;
}

function checkIfBillReceiptPaid(item) {
    if (item == 'Bill') {

        if (bills_and_receipts_object.value?.bill_workflow?.status_name == 'Paid') {
            return true

        }
        return false


    }
    else if (item == 'Receipt') {

        if (bills_and_receipts_object.value?.bills_invoices?.[0].invoice_receipt?.receipt?.amount) {
            return true

        }
        return false

    }

}

function returnBillReceiptString(item) {
    if (item == 'Bill') {

        if (bills_and_receipts_object.value?.bill_workflow?.status_name == 'Paid') {
            return 'Paid'

        }
        return 'Unpaid'


    }
    else if (item == 'Receipt') {

        if (bills_and_receipts_object.value?.bills_invoices?.[0].invoice_receipt?.receipt?.amount) {
            return 'Paid'

        }
        return 'Unpaid'

    }

}

function onDownloadBillClicked(bill_object) {
  downloadFile({ billId: props.demolition_application.building_demolition_bill.bills_id, billNumber: props.demolition_application.building_demolition_bill.bills.bill_number });

}

function onDownloadReceiptClicked(recept_object) {
  if (props.demolition_application.building_demolition_bill.bills.bills_invoices?.[0].invoice_receipt?.receipt?.receipt_number) {
    const recept_num = props.demolition_application.building_demolition_bill.bills.bills_invoices?.[0].invoice_receipt?.receipt?.receipt_number
    downloadFile({ recept_num });

  }
  else {
    snackBarErrorMessage("No Receipt Available, Please Pay for this Bill.", 5000)

  }


}



onMounted(async () => {
    console.log("props.demolition_application")
    console.log(props.demolition_application)

    const workflow_approvers = await getWorkflowApprovers("Building Demolitions", props.demolition_application.id)

    if (workflow_approvers.length != undefined) {
        workflow_approvers_array.value = workflow_approvers

    }

    demolitionData.demolition_reason = props.demolition_application

    const demolitionData = {
        demolition_reason: null,

    }

    console.log("workflow_approvers_array")
    console.log(workflow_approvers_array)


})



</script>

<template>
    <v-container fluid class="pa-0">
        <v-snackbar v-model="snackbar_info.snackbar" :timeout="snackbar_info.snackbar_timeout" top
            :color="snackbar_info.color">
            <span>{{ snackbar_info.snackbar_message }}</span>
        </v-snackbar>

        <v-row>
            <v-col cols="12" md="1">
                <BackButton @click="$emit('goBackClicked')">
                    Back To Renovations
                </BackButton>
            </v-col>
            <v-col cols="12" md="10">
                <div class="text-h6 text-center font-weight-bold primary--text">
                    Renovation Details
                </div>
            </v-col>
            <v-spacer />
        </v-row>

        <v-row justify-md="center">
            <v-col v-if="$mobileBreakpoint" cols="12">
                <Table :headers="smallScreenHouseDetails.tableHeaders"
                    :items="smallScreenHouseDetails.houseDetailsEnclosedInArray">
                </Table>
            </v-col>

            <template v-else>
                <v-col v-for="(houseDetailSection, sectionName, index) in buildingDetails" :key="sectionName" cols="12"
                    md="6">

                    <v-row v-if="index == 0">
                        <v-col cols="auto">
                            <ViewItemSectionTitle>Building Plan Details</ViewItemSectionTitle>
                        </v-col>
                    </v-row>

                    <v-simple-table dense class="building_application-details-table">
                        <tbody>
                            <tr v-for="(houseAttribute, attributeTitle) in houseDetailSection" :key="attributeTitle">
                                <td class="font-weight-bold">
                                    {{ attributeTitle }}
                                </td>
                                <td>
                                    <template v-if="attributeTitle !== 'Occupied'">
                                        {{ houseAttribute }}</template>
                                    <template v-else>
                                        <v-icon v-if="houseAttribute === true" color="success">mdi-check-circle</v-icon>
                                        <v-icon v-else color="error">mdi-close</v-icon>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </template>
        </v-row>

        <v-row>
            <v-col cols="auto">
                <ViewItemSectionTitle>Reason for Demolishing</ViewItemSectionTitle>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <LabelledField label="Demolition Reason" required>
                    <v-textarea class="text-field-background" outlined dense required
                        v-model="demolitionData.demolition_reason" hide-details="auto"></v-textarea>
                </LabelledField>

            </v-col>

        </v-row>

        <v-row>
            <v-col cols="auto">
                <ViewItemSectionTitle>Bills and Receipts</ViewItemSectionTitle>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <Table :headers="[
                    {
                        text: 'No.',
                        value: 'id',

                    },
                    {
                        text: 'Type',
                        value: 'type',

                    },
                    {
                        text: 'Number',
                        value: 'number',
                        fn: (bills_and_receipts_array) => {
                            if (bills_and_receipts_array.type == 'Bill') {
                                return bills_and_receipts_object?.bill_number

                            } else if (bills_and_receipts_array.type == 'Receipt') {
                                return bills_and_receipts_object?.bills_invoices?.[0].invoice_receipt?.receipt?.receipt_number

                            }

                            return ''

                        }

                    },
                    {
                        text: 'date',
                        value: 'Date',
                        fn: (bills_and_receipts_array) => {
                            if (bills_and_receipts_array.type == 'Bill') {
                                return getFormattedBillDate(bills_and_receipts_object?.created_at)

                            } else if (bills_and_receipts_array.type == 'Receipt') {
                                if (bills_and_receipts_object?.bills_invoices?.[0].invoice_receipt?.receipt?.payment_date) {
                                    return getFormattedBillDate(bills_and_receipts_object?.bills_invoices?.[0].invoice_receipt?.receipt?.payment_date)
                                }

                            }

                            return ''

                        }

                    },
                    {
                        text: 'amount',
                        value: 'Amount',
                        fn: (bills_and_receipts_array) => {
                            if (bills_and_receipts_array.type == 'Bill') {
                                return getFormattedMoney(bills_and_receipts_object?.amount)

                            } else if (bills_and_receipts_array.type == 'Receipt') {
                                return getFormattedMoney(bills_and_receipts_object?.bills_invoices?.[0].invoice_receipt?.receipt?.amount)

                            }

                            return ''

                        }

                    },
                    {
                        text: 'status',
                        action: true,
                        value: 'status',

                    },

                    {
                        text: 'Download',
                        value: 'download',
                        action: true

                    },


                ]" dense :items="bills_and_receipts_array" bodyClasses="text-caption">

                    <template #item.status="{ item: bills_and_receipts_array_item }">
                        <span :class="{
                            'font-weight-bold': true,
                            'green--text': checkIfBillReceiptPaid(bills_and_receipts_array_item.type),
                            'red--text': !checkIfBillReceiptPaid(bills_and_receipts_array_item.type),
                        }">{{ returnBillReceiptString(bills_and_receipts_array_item.type) }}</span>
                    </template>

                    <template #item.download="{ item: bills_and_receipts_array_item }">
                        <ActionButton v-if="bills_and_receipts_array_item.type == 'Bill'" :action="actions.downloadBill"
                            inTable @click="onDownloadBillClicked(bills_and_receipts_array_item)" />
                        <ActionButton v-else :action="actions.downloadReceipt" inTable
                            @click="onDownloadReceiptClicked(bills_and_receipts_array_item)" />
                    </template>

                </Table>

            </v-col>
        </v-row>

        <v-row>
            <v-col cols="auto">
                <ViewItemSectionTitle>Workflow</ViewItemSectionTitle>
            </v-col>
        </v-row>

        <v-row>
      <v-col cols="12">
        <Table :headers="[
          {
            text: 'User',
            value: 'user',
          },
          {
            text: 'Role',
            value: 'role',
          },
          {
            text: 'Action',
            value: 'action',
          },
          {
            text: 'Comment',
            value: 'comment',
          },
          {
            text: 'Approval Date',
            value: 'actioned',
          },

        ]" dense :items="workflow_approvers_array" bodyClasses="text-caption">

        </Table>

      </v-col>
    </v-row>



    </v-container>

</template>